import { useState } from 'react';
import { useDarkMode } from '../../context/DarkModeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { AccountCircle, ArrowDropDown, Logout } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';

function MainHeader({ userName, activePage }) {
    const { darkMode, setDarkMode } = useDarkMode();
    const { handleLogout, user } = useAuth();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const confirmLogout = () => {
        handleLogout();
        setShowLogoutModal(false);
    };

    return (
        <div className="sticky top-0 z-10 flex items-center px-6 py-3 bg-white dark:bg-slate-800 shadow-md">
            <div className="flex items-center">
                <h1 className="mr-2 w-max text-2xl font-semibold text-slate-800 dark:text-white">{activePage}</h1>
            </div>
            <div className="w-full"></div>
            <div className="flex items-center justify-end sm:space-x-2">
                <div className="relative inline-block text-left">
                    <div className="flex items-center">
                        <div
                            onClick={() => setDarkMode(!darkMode)}
                            className={`${darkMode ? 'text-[#f2c75a]' : 'text-[#6d5dd6]'} hover:cursor-pointer`}
                        >
                            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                        </div>

                        {/* Profile Icon with Dropdown */}
                        <div
                            className="flex w-max items-center space-x-2 rounded-xl ms-2 hover:cursor-pointer relative"
                            onMouseEnter={() => setShowDropdown(true)}
                            onMouseLeave={() => setShowDropdown(false)}
                        >
                            <div className="flex items-center space-x-1 rounded-full bg-gray-500/15 p-2">
                                {user?.profilePhoto ? (
                                    <img
                                        src={user?.profilePhoto}
                                        alt="Profile Picture"
                                        width={24}
                                        height={24}
                                        className="rounded-full"
                                    />
                                ) : (
                                    <AccountCircle />
                                )}
                                <p className="hidden text-xs font-semibold sm:flex text-black dark:text-white">
                                    {userName}
                                </p>
                                <ArrowDropDown className="text-black dark:text-white" />
                            </div>
                            {showDropdown && (
                                <div
                                    className={`absolute top-[40px] right-0 pt-2 w-max-content rounded-lg shadow-lg text-sm z-50 ${
                                        darkMode ? 'bg-gray-800 text-gray-300' : 'bg-white text-gray-700'
                                    }`}
                                >
                                    <button
                                        onClick={() => setShowLogoutModal(true)}
                                        className="flex items-center gap-2 px-4 py-2 w-full text-left hover:text-red-500"
                                    >
                                        <Logout /> Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showLogoutModal && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white dark:bg-slate-800 p-12 rounded-lg shadow-lg w-70 text-center">
                        <Logout className="text-red-500 mb-3" style={{ fontSize: '80px' }}/>
                        <h2 className="text-xl font-semibold text-slate-800 dark:text-white">Logout</h2>
                        <p className="mt-2 text-gray-600 dark:text-gray-300">Are you sure you want to log out?</p>
                        <div className="mt-4 flex flex-col justify-center gap-4">
                            <button
                                onClick={confirmLogout}
                                className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
                            >
                                Yes, Log Me Out
                            </button>
                            <button
                                onClick={() => setShowLogoutModal(false)}
                                className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-slate-800 dark:text-white rounded-full hover:bg-gray-400 dark:hover:bg-gray-600"
                            >
                                No, I&apos;m Staying
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MainHeader;
