import React, { useEffect, useState } from 'react';
import { useSettings } from '../context/SettingsContext';
import ErrorIcon from '@mui/icons-material/Error';
import LogoLoading from '../components/common/LogoLoading';

function Home({ setActivePage }) {
	const { settings, updateSetting, settingsLoading } = useSettings({});
	const [isBannerActive, setIsBannerActive] = useState(false);
	const [isAnnouncementsActive, setIsAnnouncementsActive] = useState(false);

	useEffect(() => {
		const currentDateTime = new Date();
		const expirationDate = new Date(settings?.general?.alertBanner?.expirationDate);

		setIsBannerActive(settings?.general?.alertBanner?.showAlertBanner && expirationDate > currentDateTime);
		setIsAnnouncementsActive(settings?.general?.announcements?.showAnnouncements);

		return () => {
			setIsBannerActive(false);
			setIsAnnouncementsActive(false);
		};
	}, [settings]);

	return (
		<>
			{/* Show loading state */}
			{settingsLoading && (
				<div>
					<div className="flex items-center justify-center h-96">
						<LogoLoading />
					</div>
				</div>
			)}

			{/* Show error state if settings are not loaded */}
			{!settingsLoading && !settings && (
				<div className="flex flex-col items-center justify-center h-96 gap-3 text-black dark:text-white">
                    <ErrorIcon className="text-red-500" style={{ fontSize: '40px' }}/>
                    <h2>We encountered an issue. Please refresh the page.</h2>
				</div>
			)}

			{/* Show content if settings are loaded  */}
			{!settingsLoading && settings && (
				<div className="flex flex-col gap-3">
					{isBannerActive && (
						<div className="p-6 space-y-8 text-gray-900 rounded-lg shadow-md bg-yellow-400/80 dark:text-black">
							<h2 className="mb-5 text-2xl font-bold">
								{settings?.general?.alertBanner?.showAlertBanner &&
									settings?.general?.alertBanner?.alertTitle}
							</h2>
							<p className="flex items-center">
								{settings?.general?.alertBanner?.showAlertBanner &&
									settings?.general?.alertBanner?.alertMessage}
							</p>
						</div>
					)}
					{isAnnouncementsActive && (
						<div className="p-6 space-y-8 text-gray-900 bg-white rounded-lg shadow-md dark:bg-gray-700 dark:text-gray-100">
							<h2 className="mb-5 text-2xl font-bold">📣 Announcements</h2>
							<div className="flex items-center">
								{settings?.general?.announcements?.showAnnouncements && (
									<div>
										{settings.general.announcements.announcementMessage
											.split(/\n/g)
											.map((line, index) => (
												<span key={index}>{line || <br />}</span>
											))}
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default Home;
