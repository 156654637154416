import { useAuth } from '../../../context/AuthContext';
import Setting from '../Setting';
import SettingsGroup from '../SettingsGroup';

const ConsumptionReportSettings = () => {
    const { user } = useAuth();

	const rolesPriority = ['admin', 'operations', 'finmanager', 'finance'];
	// .find() linearly indexes [0..], so we can determine order of permissions
	const highestPermissionRole = user?.userRoles?.find(role => rolesPriority.includes(role)) || 'unknown';

    return (
        <div className="space-y-3 w-full">
        {highestPermissionRole === 'finance' || highestPermissionRole === 'finmanager' ? (
				<></>
			) : (
                <>
                    <SettingsGroup
                        settingsKey="consumptionReport"
                        settingsSubGroup="margins"
                        settingsSchema={{ margins: {} }}
                        title="Margins"
                        subTitle="Set the minimum margin and also when to display a warning."
                    >
                        {({ isEditable, handleInputChange, localSettings, settingsSubGroup }) => (
                            <div className="space-y-3">
                                <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                                    <Setting
                                        label="Minimum Margin (%)"
                                        name="minimumMargin"
                                        type="number"
                                        placeholder="Enter value"
                                        localSettings={localSettings}
                                        settingsSubGroup={settingsSubGroup}
                                        handleInputChange={handleInputChange}
                                        disabled={!isEditable}
                                    />
                                    <Setting
                                        label="Warning Margin (%)"
                                        name="warningMargin"
                                        type="number"
                                        placeholder="Enter value"
                                        localSettings={localSettings}
                                        settingsSubGroup={settingsSubGroup}
                                        handleInputChange={handleInputChange}
                                        disabled={!isEditable}
                                    />
                                </div>
                            </div>
                        )}
                    </SettingsGroup>
                </>
            )}
        </div>
    );
}; 

export default ConsumptionReportSettings;
