/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { pdf, Font } from '@react-pdf/renderer';
import ConsumptionReportPDF from './consumptionReport/consumptionReportPDF';
import calibri from '../assets/calibri.ttf';
import calibrib from '../assets/calibrib.ttf';
import calibrii from '../assets/calibrii.ttf';
import calibriz from '../assets/calibriz.ttf';
import 'react-datepicker/dist/react-datepicker.css';
import { FaUser, FaTable, FaFileInvoice } from 'react-icons/fa';
import axios from 'axios';
import { SalesOrderPDFBasic } from './SO_NCE_template_basic';
import 'rc-slider/assets/index.css';
import { BlobServiceClient } from '@azure/storage-blob';
import { Buffer } from 'buffer'; //need this for blob upload
import { generateSHA256, formatMoney, uploadSalesOrderNCEtoCosmos, uploadConsumptionReportToCosmos } from '../utils';
import { serverAddress } from '../authConfig';
import { useAuth } from '../context/AuthContext';
window.Buffer = Buffer;
import ClientView from './consumptionReport/ClientView';
import ProductView from './consumptionReport/ProductTableView';
import SalesOrderView from './consumptionReport/SalesOrderView';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

Font.register({
	family: 'Calibri',
	fonts: [
		{ src: calibri },
		{ src: calibrib, fontWeight: 'bold' },
		{ src: calibrii, fontStyle: 'italic' },
		{ src: calibriz, fontWeight: 'bold', fontStyle: 'italic' },
	],
});

const platSupportOptions = [
	{ value: 'No', label: 'No' },
	{ value: 'Yes', label: 'Yes' },
];

const currencyOptions = [
	{ value: 'USD', label: '🇺🇸' },
	{ value: 'CAD', label: '🇨🇦' },
	{ value: 'GBP', label: '🇬🇧' },
	{ value: 'EUR', label: '🇪🇺' },
	{ value: 'CHF', label: '🇨🇭' },
	{ value: 'DKK', label: '🇩🇰' },
	{ value: 'NOK', label: '🇳🇴' },
	{ value: 'SEK', label: '🇸🇪' },
];

const currencySymbols = {
	USD: '$',
	CAD: '$',
	GBP: '£',
	EUR: '€',
	CHF: 'Chf.',
	DKK: 'kr',
	NOK: 'kr',
	SEK: 'kr',
};

const currentVendorOptions = [
	{ value: 'Current Vendor', label: 'Current Vendor' },
	{ value: 'Microsoft', label: 'Microsoft' },
	{ value: 'CDW', label: 'CDW' },
	{ value: 'Rackspace', label: 'Rackspace' },
	{ value: 'Appriver', label: 'Appriver' },
	{ value: 'Pax8', label: 'Pax8' },
	{ value: 'PC Connection', label: 'PC Connection' },
	{ value: 'Dell', label: 'Dell' },
	{ value: 'Insight Global', label: 'Insight Global' },
	{ value: 'Ingram Micro', label: 'Ingram Micro' },
	{ value: 'Synnex', label: 'Synnex' },
	{ value: 'TechData', label: 'TechData' },
	{ value: 'MessageOpps', label: 'MessageOpps' },
	{ value: 'CSP', label: 'CSP' },
	{ value: 'MSP', label: 'MSP' },
];

const netTermsOptions = [
	{ value: 'NONE', label: 'NONE' },
	{ value: 'Net 7', label: 'Net 7' },
	{ value: 'Net 15', label: 'Net 15' },
	{ value: 'Net 30', label: 'Net 30' },
	{ value: 'Net 45', label: 'Net 45' },
	{ value: 'Net 60', label: 'Net 60' },
	{ value: 'Net 90', label: 'Net 90' },
];

const billingFrequencyOptions = [
	{ value: 'NONE', label: 'NONE' },
	{ value: 'Monthly', label: 'Monthly' },
	{ value: 'Annually', label: 'Annually' },
];

function applyDiscounts(row, tttDiscount, currentVendorDiscount) {
	const tttPrice =
		row.product && row.product.label.startsWith('Platinum Support') ? row.msrp : row.msrp * (1 - tttDiscount / 100);

	const currentVendorPrice = row.msrp * (1 - currentVendorDiscount / 100);
	let tttTotal = row.assignedSeats * tttPrice;
	let currentVendorTotal = row.assignedSeats * currentVendorPrice;
	let lineItemSavings = currentVendorTotal - tttTotal;

	if (row.assignedSeats === 0) {
		tttTotal = 0;
	}

	if (row.totalSeats === 0) {
		currentVendorTotal = 0;
	}

	const tttPricePerSeat = row.assignedSeats === 0 ? 0 : tttPrice;
	const currentVendorPricePerSeat = row.totalSeats === 0 ? 0 : currentVendorPrice;

	return {
		...row,
		currentVendorPrice: currentVendorPricePerSeat,
		tttPrice: tttPricePerSeat,
		tttTotal,
		currentVendorTotal,
		lineItemSavings,
	};
}

const CReport = () => {
	const { user, accessToken } = useAuth();

	const [rows, setRows] = useState([
		{
			id: uuidv4(),
			product: null,
			assignedSeats: 0,
			totalSeats: 0,
			msrp: 0,
			currentVendorPrice: 0,
			tttPrice: 0,
			lineItemSavings: 0,
			effectiveStartDate: new Date(),
			// effectiveEndDate: new Date(),
		},
	]);
	// const [rows, setRows] = useState([]);

	const [salespersons, setSalespersons] = useState([]);
	const [options, setOptions] = useState([]);
	const [currency, setCurrency] = useState('USD');
	const [currentVendor, setCurrentVendor] = useState('Current Vendor');
	const [tttDiscount, setTttDiscount] = useState(0);
	const [currentVendorDiscount, setCurrentVendorDiscount] = useState(0);

	const [clientName, setClientName] = useState('');
	const [clientCompany, setClientCompany] = useState('');
	const [clientEmail, setClientEmail] = useState('');
	const [clientPhone, setClientPhone] = useState('');

	const [selectedSender, setSelectedSender] = useState(null);
	const [senderEmail, setSenderEmail] = useState('');
	const [senderPhone, setSenderPhone] = useState('');

	const [selectedDate, setSelectedDate] = useState('');
	const [reference, setReference] = useState('');
	const [currentDate, setCurrentDate] = useState(new Date());

	const [referenceCode, setReferenceCode] = useState('');
	const [view, setView] = useState('client');
	const [activeSection, setActiveSection] = useState('info');
	//const [salesforceLeadId, setSalesforceLeadId] = useState("");

	//sales order
	const today = new Date();
	const [salesOrderView, setSalesOrderView] = useState(false);
	const [term, setTerm] = useState('');
	const [offerValidThrough, setOfferValidThrough] = useState(() => {
		return today.setMonth(today.getMonth() + 1);
	});
	const [defaultServiceStartDate, setDefaultServiceStartDate] = useState(new Date());
	const [defaultServiceEndDate, setDefaultServiceEndDate] = useState(() => {
		return today.setFullYear(today.getFullYear() + 1);
	});
	const [netTerms, setNetTerms] = useState('');
	const [billingFrequency, setBillingFrequency] = useState('');
	const [companyAddress, setCompanyAddress] = useState('');
	const [companyCity, setCompanyCity] = useState('');
	const [companyState, setCompanyState] = useState('');
	const [companyZip, setCompanyZip] = useState('');
	const [companyCountry, setCompanyCountry] = useState('');
	const [products, setProducts] = useState([]);

	const [platinumSupport, setPlatinumSupport] = useState('NONE');

	//states to fetch JSON CRs
	const [jsonFiles, setJsonFiles] = useState([]);
	const location = useLocation();
	const { activeCReportId } = location.state || {};

	const queryClient = useQueryClient();

	useEffect(() => {
		console.log('Rows state has changed', rows);
	}, [rows]);

	useEffect(() => {
		if (activeCReportId) {
			fetchConsumptionReportDetails(activeCReportId, accessToken, serverAddress);
		}
	}, [activeCReportId, accessToken, serverAddress]);

	// Function to fetch consumption report details
	const fetchConsumptionReportDetails = async (reportId, accessToken, serverAddress) => {
		try {
			const response = await axios.get(`${serverAddress}/consumption-reports/${reportId}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': user?.userName,
				},
			});
			if (response.status === 200) {
				const reportDetails = response.data;
				console.log('Consumption Report Details:', reportDetails);
				mapReportDetailsToState(reportDetails);
			}
		} catch (error) {
			console.error('Error fetching consumption report details:', error);
		}
	};

	const mapReportDetailsToState = details => {
		setClientCompany(details.clientCompany || '');
		setClientName(details.clientName || '');
		setClientEmail(details.clientEmail || '');
		setClientPhone(details.clientPhone || '');
		setCurrency(details.currency || 'USD');
		setCurrentVendor(details.currentVendor || 'Current Vendor');

		// Ensure that selectedSender contains value, label, email, and phone
		const sender = {
			full_name: details.selectedSender?.full_name || details.selectedSender?.label || '', // Use full_name or label
			value: details.selectedSender?.value || details.selectedSender?.full_name || '', // Ensure value is set
			label: details.selectedSender?.label || details.selectedSender?.full_name || '', // Ensure label is set
			email: details.selectedSender?.email || '',
			phone: details.selectedSender?.phone || '',
		};

		setSelectedSender(sender); // Set the updated sender object
		setReferenceCode(details.referenceCode || '');

		const mappedRows = details.rows.map(row => ({
			id: row.id,
			product: {
				value: row.product.value,
				label: row.product.label,
				msrp: row.product.msrp,
			},
			assignedSeats: row.assignedSeats,
			totalSeats: row.totalSeats,
			msrp: row.msrp,
			currentVendorPrice: row.currentVendorPrice,
			tttPrice: row.tttPrice,
			lineItemSavings: row.lineItemSavings,
			effectiveStartDate: new Date(row.effectiveStartDate),
			// effectiveEndDate: new Date(row.effectiveEndDate),
		}));

		setRows(mappedRows);
	};

	useEffect(() => {
		const loadJsonFiles = async () => {
			const files = await fetchJsonFiles();
			setJsonFiles(files);
		};

		loadJsonFiles();
	}, []);

	useEffect(() => {
		const generateReferenceCode = () => {
			if (currentDate && selectedSender && selectedSender.full_name) {
				const datePart = currentDate
					.toLocaleDateString('en-US', {
						month: '2-digit',
						day: '2-digit',
						year: '2-digit',
					})
					.replace(/\//g, '');
				const initials = selectedSender.full_name
					.split(' ')
					.map(name => name.charAt(0))
					.join('');
				setReferenceCode(`${datePart}-${initials}`);
			}
		};

		generateReferenceCode();
	}, [currentDate, selectedSender]);

	useEffect(() => {
		if (selectedSender) {
			if (!senderEmail) setSenderEmail(selectedSender.email);
			if (!senderPhone) setSenderPhone(selectedSender.phone);
			generateReference(selectedDate, selectedSender);
		}
	}, [selectedSender, senderEmail, senderPhone]);

	const generateReference = (date, sender) => {
		if (!date || !sender) return;

		const [year, month, day] = date.split('-');
		const initials = sender.full_name
			.split(' ')
			.map(n => n[0])
			.join('');
		const refCode = `${month}${day}${year.slice(-2)}-${initials}`;
		setReference(refCode);
	};

	const requestIdRef = useRef(0);

	useEffect(() => {
		// Cancel token and request ID tracking
		const source = axios.CancelToken.source();
		const currentRequestId = ++requestIdRef.current; // Increment and assign unique request ID

		const fetchProducts = async () => {
			setProducts([]);
			setOptions([]);

			try {
				console.log(`Fetching products for currency: ${currency}, request ID: ${currentRequestId}`);

				const response = await axios.get(`${serverAddress}/products?pk_filter=${currency}`, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-User-Name': `${user?.userName}`,
					},
					cancelToken: source.token,
				});

				const matchList = response.data;

				const filteredProducts = matchList
					.filter(item =>
						item.pk_msrp_variants?.some(
							variant => variant.pk_msrp.includes('P1Y|monthly') && variant.pk_msrp.endsWith(currency)
						)
					)
					.map(item => {
						const variant = item.pk_msrp_variants?.find(
							variant => variant.pk_msrp.includes('P1Y|monthly') && variant.pk_msrp.endsWith(currency)
						);

						const erpPrice = variant?.erp_price || 0;

						return {
							value: item.sku_title,
							label: item.sku_title,
							msrp: erpPrice,
						};
					})
					.sort((a, b) => a.label.localeCompare(b.label));

				if (currentRequestId === requestIdRef.current) {
					setProducts(filteredProducts);
					setOptions(filteredProducts);
				} else {
					console.log(`Request ID ${currentRequestId} is outdated. Ignoring results.`);
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log('Request canceled:', error.message);
				} else {
					console.error('Error fetching products:', error);
				}
			}
		};

		fetchProducts();

		return () => {
			source.cancel('Operation canceled due to new request or component unmount.');
		};
	}, [currency, serverAddress, accessToken]);

	// useEffect(() => {
	// 	if (products.length > 0 && rows.length > 0) {
	// 		console.log('Updating rows with products:', products);
	// 		console.log('Current rows:', rows);

	// 		setRows((prevRows) =>
	// 			prevRows.map((row) => {
	// 				const msrp = row.product
	// 					? products.find((product) => product.value === row.product.value)?.msrp || 0
	// 					: 0;

	// 				const currentVendorPrice = row.assignedSeats > 0 && row.totalSeats > 0 ? msrp : 0;
	// 				const tttPrice = row.assignedSeats > 0 && row.totalSeats > 0 ? msrp : 0;

	// 				return {
	// 					...row,
	// 					msrp: msrp,
	// 					currentVendorPrice: currentVendorPrice,
	// 					tttPrice: tttPrice,
	// 				};
	// 			})
	// 		);
	// 	}
	// }, [products, rows]);

	useEffect(() => {
		if (products.length > 0) {
			console.log('Updating rows with products:', products);

			setRows(prevRows =>
				prevRows.map(row => {
					let msrp = row.product
						? products.find(product => product.value === row.product.value)?.msrp || 0
						: 0;

					const currentVendorPrice = row.assignedSeats > 0 && row.totalSeats > 0 ? msrp : 0;
					const tttPrice = row.assignedSeats > 0 && row.totalSeats > 0 ? msrp : 0;

					return {
						...row,
						msrp: msrp,
						currentVendorPrice: currentVendorPrice,
						tttPrice: tttPrice,
					};
				})
			);
		}
	}, [products]); // Remove `rows` from the dependency array

	useEffect(() => {
		const table = document.getElementById('draggable-table');
		if (!table) {
			return;
		}
		let draggedRow = null;

		const handleDragStart = e => {
			if (e.target.closest('tbody tr')) {
				draggedRow = e.target;
				e.dataTransfer.effectAllowed = 'move';
			}
		};

		const handleDragOver = e => {
			e.preventDefault();
			e.dataTransfer.dropEffect = 'move';
			const target = e.target.closest('tbody tr');
			if (target && target !== draggedRow) {
				const rect = target.getBoundingClientRect();
				const next = (e.clientY - rect.top) / (rect.bottom - rect.top) > 0.5;
				target.parentNode.insertBefore(draggedRow, (next && target.nextSibling) || target);
			}
		};

		const handleDrop = e => {
			e.preventDefault();
			const updatedRows = Array.from(table.querySelectorAll('tbody tr'))
				.filter(tr => tr.dataset.id)
				.map(tr => rows.find(row => row.id === tr.dataset.id));
			setRows(updatedRows);
		};

		table.addEventListener('dragstart', handleDragStart);
		table.addEventListener('dragover', handleDragOver);
		table.addEventListener('drop', handleDrop);

		return () => {
			table.removeEventListener('dragstart', handleDragStart);
			table.removeEventListener('dragover', handleDragOver);
			table.removeEventListener('drop', handleDrop);
		};
	}, [rows]);

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}

		const updatedRows = Array.from(rows);
		const [removed] = updatedRows.splice(result.source.index, 1);
		updatedRows.splice(result.destination.index, 0, removed);

		setRows(updatedRows);
	};

	const handleAddRow = () => {
		const newRow = {
			id: uuidv4(),
			product: null,
			assignedSeats: 0,
			totalSeats: 0,
			msrp: 0,
			currentVendorPrice: 0,
			tttPrice: 0,
			lineItemSavings: 0,
			effectiveStartDate: defaultServiceStartDate,
		};

		const updatedRow = {
			...newRow,
			tttPrice: newRow.msrp * (1 - tttDiscount / 100),
			currentVendorPrice: newRow.msrp * (1 - currentVendorDiscount / 100),
			lineItemSavings: Math.max(newRow.currentVendorPrice - newRow.tttPrice, 0) * newRow.totalSeats,
		};

		setRows([...rows, updatedRow]);
	};

	const handleDeleteRow = id => {
		setRows(rows.filter(row => row.id !== id));
	};

	const [tempValues, setTempValues] = useState({});

	useEffect(() => {
		setRows(prevRows =>
			prevRows.map(row => ({
				...row,
				effectiveStartDate: new Date(defaultServiceStartDate),
			}))
		);
	}, [defaultServiceStartDate]);

	const handleInputChange = (e, id, field) => {
		const value = e.target.value;
		setTempValues(prevTempValues => ({
			...prevTempValues,
			[`${id}-${field}`]: value,
		}));
	};
	const handleInputBlur = (id, field) => {
		let value = tempValues[`${id}-${field}`];

		if (field.includes('Date')) {
			value = new Date(value);
			if (isNaN(value.getTime())) {
				value = new Date();
			}
		} else {
			value = value !== undefined ? parseFloat(value) : rows.find(row => row.id === id)[field];
		}

		setRows(prevRows =>
			prevRows.map(row => {
				if (row.id === id) {
					const updatedRow = { ...row, [field]: value };

					const recalculatedRow = applyDiscounts(updatedRow, tttDiscount, currentVendorDiscount);

					return recalculatedRow;
				}
				return row;
			})
		);

		setTempValues(prevTempValues => ({
			...prevTempValues,
			[`${id}-${field}`]: undefined,
		}));
	};

	const handleSelectChange = (selectedOption, id) => {
		const platinumSupportOptions = [
			{
				value: 'Platinum Support (Basic)',
				label: 'Platinum Support (Basic)',
				msrp: 250,
			},
			{
				value: 'Platinum Support (Standard)',
				label: 'Platinum Support (Standard)',
				msrp: 450,
			},
			{
				value: 'Platinum Support (Premium)',
				label: 'Platinum Support (Premium)',
				msrp: 1800,
			},
			{
				value: 'Platinum Support (Elite)',
				label: 'Platinum Support (Elite)',
				msrp: 4500,
			},
		];

		const msrp = selectedOption ? selectedOption.msrp : 0;
		setRows(
			rows.map(row => {
				if (row.id === id) {
					const isPlatinumSupport = platinumSupportOptions.some(
						option => option.value === selectedOption.value
					);
					const updatedRow = applyDiscounts(
						{
							...row,
							product: selectedOption,
							msrp: isPlatinumSupport ? 250 : msrp,
							tttPrice: isPlatinumSupport ? 250 : row.tttPrice,
							currentVendorPrice: isPlatinumSupport ? 0 : row.currentVendorPrice,
						},
						tttDiscount,
						currentVendorDiscount
					);
					return updatedRow;
				}
				return row;
			})
		);
	};

	const handleEditClick = id => {
		setRows(rows.map(row => (row.id === id ? { ...row, isEditing: true } : row)));
	};

	const handleSaveClick = id => {
		setRows(
			rows.map(row =>
				row.id === id
					? {
							...row,
							isEditing: false,
							product: row.product?.value ? row.product : { label: 'Select a product', value: '' },
						}
					: row
			)
		);
	};

	const handleTttDiscountChange = value => {
		setTttDiscount(value);
		setRows(
			rows.map(row => {
				return applyDiscounts(row, value, currentVendorDiscount);
			})
		);
	};

	const handleCurrentVendorDiscountChange = value => {
		setCurrentVendorDiscount(value);
		setRows(
			rows.map(row => {
				return applyDiscounts(row, tttDiscount, value);
			})
		);
	};

	const handleDownloadPDF = async () => {
		const updatedRows = rows.map(row => applyDiscounts(row, tttDiscount, currentVendorDiscount));

		let isUploaded = false;
		if (activeCReportId) {
			isUploaded = await uploadConsumptionReportToCosmos(
				updatedRows,
				clientCompany,
				clientEmail,
				clientName,
				clientPhone,
				tttDiscount,
				currentVendorDiscount,
				selectedSender,
				referenceCode,
				currency,
				user?.userName,
				serverAddress,
				accessToken,
				activeCReportId
			);
			queryClient.invalidateQueries({
				queryKey: ['listSalespersonsConsumptionReports'],
			});
			console.log('Updated existing consumption report in Cosmos DB');
		} else {
			isUploaded = await uploadConsumptionReportToCosmos(
				updatedRows,
				clientCompany,
				clientEmail,
				clientName,
				clientPhone,
				tttDiscount,
				currentVendorDiscount,
				selectedSender,
				referenceCode,
				currency,
				user?.userName,
				serverAddress,
				accessToken
			);
			queryClient.invalidateQueries({
				queryKey: ['listSalespersonsConsumptionReports'],
			});
			console.log('Created new consumption report in Cosmos DB');
		}

		if (isUploaded === true) {
			const pdfBlob = await pdf(
				<ConsumptionReportPDF
					rows={updatedRows}
					clientName={clientName}
					clientCompany={clientCompany}
					clientEmail={clientEmail}
					clientPhone={clientPhone}
					selectedSender={selectedSender}
					senderEmail={senderEmail}
					senderPhone={senderPhone}
					totalCurrentVendor={totalCurrentVendor}
					totalTtt={totalTtt}
					totalSavings={totalSavings}
					totalAnnualSavings={totalAnnualSavings}
					currency={currency}
					currentDate={currentDate}
					referenceCode={referenceCode}
					currentVendor={currentVendor}
				/>
			).toBlob();
			const pdfUrl = URL.createObjectURL(pdfBlob);

			const uniqueId = uuidv4();
			const localFileName = `${clientCompany}_${uniqueId}_creport.pdf`;

			const downloadLink = document.createElement('a');
			downloadLink.href = pdfUrl;
			downloadLink.download = localFileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(pdfUrl);
		}
	};

	const handlePreviewPDF = async () => {
		const updatedRows = rows.map(row => applyDiscounts(row, tttDiscount, currentVendorDiscount));

		let isUploaded = false;
		try {
			if (activeCReportId) {
				isUploaded = await uploadConsumptionReportToCosmos(
					updatedRows,
					clientCompany,
					clientEmail,
					clientName,
					clientPhone,
					tttDiscount,
					currentVendorDiscount,
					selectedSender,
					referenceCode,
					currency,
					user?.userName,
					serverAddress,
					accessToken,
					activeCReportId
				);
				queryClient.invalidateQueries({
					queryKey: ['listSalespersonsConsumptionReports'],
				});

				console.log('Updated existing consumption report in Cosmos DB');
			} else {
				isUploaded = await uploadConsumptionReportToCosmos(
					updatedRows,
					clientCompany,
					clientEmail,
					clientName,
					clientPhone,
					tttDiscount,
					currentVendorDiscount,
					selectedSender,
					referenceCode,
					currency,
					user?.userName,
					serverAddress,
					accessToken
				);
				queryClient.invalidateQueries({
					queryKey: ['listSalespersonsConsumptionReports'],
				});
			}
		} catch (error) {
			console.error(error);
		}

		if (isUploaded === true) {
			const pdfBlob = await pdf(
				<ConsumptionReportPDF
					rows={updatedRows}
					clientName={clientName}
					clientCompany={clientCompany}
					clientEmail={clientEmail}
					clientPhone={clientPhone}
					selectedSender={selectedSender}
					senderEmail={senderEmail}
					senderPhone={senderPhone}
					totalCurrentVendor={totalCurrentVendor}
					totalTtt={totalTtt}
					totalSavings={totalSavings}
					totalAnnualSavings={totalAnnualSavings}
					currency={currency}
					currentDate={currentDate}
					referenceCode={referenceCode}
					currentVendor={currentVendor}
				/>
			).toBlob();
			const pdfUrl = URL.createObjectURL(pdfBlob);
			const pdfUrlWithParams = `${pdfUrl}#toolbar=0`;
			window.open(pdfUrlWithParams, '_blank');
		}
	};

	//fetch CR Jsons files
	const fetchJsonFiles = async () => {
		const sasToken =
			'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=3000-07-24T00:42:14Z&st=2024-07-23T16:42:14Z&spr=https,http&sig=ahO%2Bf%2BP7cJGC8xaHdQ7EbfbLBS%2FzjJx9NBMIa3CBibc%3D';
		const account = 'dwdeprepaidtttdatalake';
		const containerName = 'sh-consumption-reports';

		const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sasToken}`);
		const containerClient = blobServiceClient.getContainerClient(containerName);

		const jsonFiles = [];

		for await (const blob of containerClient.listBlobsFlat()) {
			if (blob.name.endsWith('.json')) {
				jsonFiles.push({
					name: blob.name,
					lastModified: blob.properties.lastModified,
				});
			}
		}
		//sort by newest
		jsonFiles.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));

		return jsonFiles;
	};

	useEffect(() => {
		const fetchSalespersons = async () => {
			try {
				const response = await axios.get(`${serverAddress}/salespersons/account-executives`, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-User-Name': `${user?.userName}`,
					},
				});
				if (response.status === 200) {
					setSalespersons(response.data.salespersons);
				} else {
					console.log('Failed to fetch salespersons. Status:', response.status);
				}
			} catch (error) {
				console.error('Error fetching salespersons:', error);
			}
		};

		fetchSalespersons();
	}, [serverAddress, accessToken, user?.userName]);

	//fetch CR Jsons inner contents
	const fetchJsonContent = async fileName => {
		const sasToken =
			'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=3000-07-24T00:42:14Z&st=2024-07-23T16:42:14Z&spr=https,http&sig=ahO%2Bf%2BP7cJGC8xaHdQ7EbfbLBS%2FzjJx9NBMIa3CBibc%3D';
		const account = 'dwdeprepaidtttdatalake';
		const containerName = 'sh-consumption-reports';

		const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sasToken}`);
		const containerClient = blobServiceClient.getContainerClient(containerName);
		const blobClient = containerClient.getBlobClient(fileName);

		try {
			const downloadBlockBlobResponse = await blobClient.download(0);
			const downloaded = await downloadBlockBlobResponse.blobBody;
			if (!downloaded) {
				throw new Error('Failed to retrieve the blob content, blobBody is undefined');
			}

			const textContent = await downloaded.text();
			return JSON.parse(textContent);
		} catch (error) {
			console.error('Error fetching JSON content:', error);
			throw error;
		}
	};

	const handleFileSelect = async event => {
		const fileName = event.target.value;

		if (!fileName) return;

		try {
			const jsonContent = await fetchJsonContent(fileName);

			setCurrency(jsonContent.currency);
			setClientCompany(jsonContent.clientCompany);
			setSelectedSender(jsonContent.selectedSender);
			setReferenceCode(jsonContent.referenceCode);

			const mappedRows = jsonContent.rows.map(jsonRow => ({
				id: jsonRow.id,
				product: jsonRow.product,
				assignedSeats: jsonRow.assignedSeats,
				tttPrice: jsonRow.tttPrice,
				isEditing: jsonRow.isEditing || false,
				tttTotal: jsonRow.tttTotal,
			}));

			setRows(mappedRows);

			console.log('Selected JSON content:', jsonContent);
		} catch (error) {
			console.error('Error handling file select:', error);
		}
	};

	const totalCurrentVendor = rows.reduce(
		(sum, row) => sum + (isNaN(row.currentVendorTotal) ? 0 : row.currentVendorTotal),
		0
	);
	const totalTtt = rows.reduce((sum, row) => sum + (isNaN(row.tttTotal) ? 0 : row.tttTotal), 0);
	const totalSavings = totalCurrentVendor - totalTtt;
	const totalAnnualSavings = totalSavings * 12;

	return (
		<div className="min-h-screen text-black dark:text-white">
			<div className="sub-menu">
				<h2
					onClick={() => setView('client')}
					className={`sub-menu-item ${view === 'client' ? 'sub-menu-item-selected' : ''}`}
				>
					Info Section
				</h2>
				<h2
					onClick={() => setView('product')}
					className={`sub-menu-item ${view === 'product' ? 'sub-menu-item-selected' : ''}`}
				>
					Product Table
				</h2>
			</div>
			<div className="fixed-container">
				{view === 'client' && (
					<ClientView
						currentDate={currentDate}
						setCurrentDate={setCurrentDate}
						referenceCode={referenceCode}
						clientName={clientName}
						setClientName={setClientName}
						clientCompany={clientCompany}
						setClientCompany={setClientCompany}
						clientEmail={clientEmail}
						setClientEmail={setClientEmail}
						clientPhone={clientPhone}
						setClientPhone={setClientPhone}
						selectedSender={selectedSender}
						userName={user?.userName}
						setSelectedSender={setSelectedSender}
						senderEmail={senderEmail}
						senderPhone={senderPhone}
						salespersons={salespersons}
						setSenderEmail={setSenderEmail}
						setSenderPhone={setSenderPhone}
					/>
				)}
				{view === 'product' && (
					<ProductView
						rows={rows}
						tttDiscount={tttDiscount}
						handleTttDiscountChange={handleTttDiscountChange}
						currentVendorDiscount={currentVendorDiscount}
						handleCurrentVendorDiscountChange={handleCurrentVendorDiscountChange}
						currentVendor={currentVendor}
						setCurrentVendor={setCurrentVendor}
						currentVendorOptions={currentVendorOptions}
						currency={currency}
						setCurrency={setCurrency}
						options={options}
						currencyOptions={currencyOptions}
						platinumSupport={platinumSupport}
						platSupportOptions={platSupportOptions}
						handleAddRow={handleAddRow}
						onDragEnd={onDragEnd}
						handleInputChange={handleInputChange}
						handleInputBlur={handleInputBlur}
						handleEditClick={handleEditClick}
						handleDeleteRow={handleDeleteRow}
						handleSaveClick={handleSaveClick}
						handleSelectChange={handleSelectChange}
						tempValues={tempValues}
						currencySymbols={currencySymbols}
						totalCurrentVendor={totalCurrentVendor}
						totalTtt={totalTtt}
						totalSavings={totalSavings}
						totalAnnualSavings={totalAnnualSavings}
						formatMoney={formatMoney}
						handlePreviewPDF={handlePreviewPDF}
						handleDownloadPDF={handleDownloadPDF}
						setSalesOrderView={setSalesOrderView}
					/>
				)}
			</div>
		</div>
	);
};

export default CReport;
