/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { pdf, Font } from '@react-pdf/renderer';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Buffer } from 'buffer'; //need this for blob upload
window.Buffer = Buffer;
import { v4 as uuidv4 } from 'uuid';
import calibri from '../assets/calibri.ttf';
import calibrib from '../assets/calibrib.ttf';
import calibrii from '../assets/calibrii.ttf';
import calibriz from '../assets/calibriz.ttf';
import 'react-datepicker/dist/react-datepicker.css';
import mollenBold from '../assets/Mollen Personal Use-Bold.otf';
import SalesOrderPDFBasic from './salesOrder/SalesOrderPdfBasic';
import { FaUser, FaTable, FaWrench } from 'react-icons/fa';
import { ImDownload, ImSearch, ImPlus, ImBin, ImShocked, ImCloudCheck } from 'react-icons/im';
import { Progress } from 'antd';
import { generateSHA256, formatMoney, uploadSalesOrderToCosmos } from '../utils';
import useDebounce from '../hooks/useDebounce';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { serverAddress } from '../authConfig';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { ToastContainer } from 'react-toastify';

Font.register({
	family: 'Mollen',
	fonts: [{ src: mollenBold, fontWeight: 'bold' }],
});

Font.register({
	family: 'Calibri',
	fonts: [
		{ src: calibri },
		{ src: calibrib, fontWeight: 'bold' },
		{ src: calibrii, fontStyle: 'italic' },
		{ src: calibriz, fontWeight: 'bold', fontStyle: 'italic' },
	],
});

const currencyOptions = [
	{ value: 'USD', label: 'USD', symbol: '$', flag: '🇺🇸' },
	{ value: 'CAD', label: 'CAD', symbol: '$', flag: '🇨🇦' },
	{ value: 'GBP', label: 'GBP', symbol: '£', flag: '🇬🇧' },
	{ value: 'EUR', label: 'EUR', symbol: '€', flag: '🇪🇺' },
	{ value: 'CHF', label: 'CHF', symbol: 'Chf.', flag: '🇨🇭' },
	{ value: 'DKK', label: 'DKK', symbol: 'kr', flag: '🇩🇰' },
	{ value: 'NOK', label: 'NOK', symbol: 'kr', flag: '🇳🇴' },
	{ value: 'SEK', label: 'SEK', symbol: 'kr', flag: '🇸🇪' },
];

const netTermsOptions = [
	// { value: "NONE", label: "NONE" },
	{ value: 'Net 7', label: 'Net 7' },
	{ value: 'Net 15', label: 'Net 15' },
	{ value: 'Monthly', label: 'Monthly' },
	{ value: 'Net 45', label: 'Net 45' },
	{ value: 'Net 60', label: 'Net 60' },
	{ value: 'Net 90', label: 'Net 90' },
	{ value: 'Due on Receipt', label: 'Due on Receipt' },
];

const billingFrequencyOptions = [
	// { value: "NONE", label: "NONE" },
	{ value: 'Monthly', label: 'Monthly' },
	{ value: 'Annually', label: 'Annually' },
];

const SOrder = () => {
	const { user, accessToken } = useAuth();
	let { chargebeeId } = useParams();

	const [formData, setFormData] = useState({
		chargebeeSubscriptionId: chargebeeId || '',
		validThrough: new Date(),
		termMonths: '',
		renewalDate: new Date(),
		netTerms: 'Monthly',
		billingFrequency: 'Monthly',
		companyName: '',
		status: 'Draft',
		salesPersonName: '',
		currency: '',
		platinumSupport: 'NONE',
	});
	const [addons, setAddons] = useState([]);
	const [editingRowId, setEditingRowId] = useState(null);
	const [editValues, setEditValues] = useState({});
	const [view, setView] = useState('client');
	const [loading, setLoading] = useState(false);
	const [progressPercent, setProgressPercent] = useState(0);
	const [error, setError] = useState('');
	const [matchList, setMatchList] = useState([]);
	const [salespersons, setSalespersons] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);
	const [totalContractValue, setTotalContractValue] = useState(0);
	const [activeSOrderId, setActiveSOrderId] = useState(null);
	const [loadingComplete, setLoadingComplete] = useState(false);

	//hook takes effect with location changes from Comission to Sales Orders
	const location = useLocation();
	const { activeSalesOrderId } = location.state || {};

	//fall back to render delayed salesperson name from dropdown
	useEffect(() => {
		if (user?.userName && !formData.salesPersonName) {
			setFormData(prevData => ({
				...prevData,
				salesPersonName: user.userName,
			}));
		}
	}, [user?.userName]);

	//reset state when different sales order is selected to be edited
	useEffect(() => {
		location.state = null; // Wipe state so editing is cancelled by refreshing page
		if (activeSalesOrderId) {
			setFormData({
				chargebeeSubscriptionId: '',
				validThrough: new Date(),
				termMonths: '',
				renewalDate: new Date(),
				netTerms: 'Monthly',
				billingFrequency: 'Monthly',
				companyName: '',
				salesPersonName: '',
				currency: '',
				platinumSupport: 'NONE',
				status: 'Draft',
			});
			setAddons([]);
			fetchSalesOrderDetails(activeSalesOrderId, accessToken, user?.userName, serverAddress);
			setActiveSOrderId(activeSalesOrderId);

			location.state = null;
		}
	}, [activeSalesOrderId]);

	//get sales orders details when edit sales orders is selections in Comissions page
	const fetchSalesOrderDetails = async (activeSalesOrderId, accessToken, userName, serverAddress) => {
		try {
			const response = await axios.get(`${serverAddress}/sales-orders/renewal/${activeSalesOrderId}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});

			if (response.status === 200) {
				const salesOrderDetails = response.data;
				const currencyCode = salesOrderDetails.currency || 'USD';

				const parseLocalDate = dateString => {
					const [year, month, day] = dateString.split('-').map(Number);
					return new Date(year, month - 1, day);
				};

				console.info(salesOrderDetails.validThrough);

				const validThroughDate = salesOrderDetails.validThrough.split('T')[0]
					? parseLocalDate(salesOrderDetails.validThrough.split('T')[0])
					: null;
				const renewalDate = salesOrderDetails.renewalDate.split('T')[0]
					? parseLocalDate(salesOrderDetails.renewalDate.split('T')[0])
					: null;

				setFormData(prevData => ({
					...prevData,
					chargebeeSubscriptionId: salesOrderDetails.chargebeeSubscriptionId,
					salesOrderId: activeSalesOrderId, //NOT SALES ORDER ID
					companyName: salesOrderDetails.companyName,
					salesPersonName: salesOrderDetails.salesPersonName,
					termMonths: salesOrderDetails.termMonths,
					billingFrequency: salesOrderDetails.billingFrequency,
					netTerms: salesOrderDetails.netTerms,
					validThrough: validThroughDate,
					renewalDate: renewalDate,
					platinumSupport: salesOrderDetails.platinumSupport,
					currency: currencyCode,
					status: salesOrderDetails.status || 'Draft',
				}));

				const matchList = await fetchMatchList(currencyCode);
				setMatchList(matchList);

				setAddons(
					salesOrderDetails.pricingSummary.map(addon => {
						// Calculate the default Effective Start Date and Effective End Date
						const effectiveStartDate = addon.effectiveStartDate
							? new Date(addon.effectiveStartDate)
							: new Date();
						const effectiveEndDate = addon.effectiveEndDate
							? new Date(addon.effectiveEndDate)
							: new Date(effectiveStartDate.setFullYear(effectiveStartDate.getFullYear() + 1));

						const matchedSkuTitle = addon.matchedSkuTitle || '';
						const matchedPkMsrp = addon.matchedPkID || '';
						const matchedUnitPrice = addon.matchedUnitPrice || '';
						const matchedUnitPriceInUsd = addon.matchedUnitPriceInUsd || '';
						const skuTitles = matchList.map(item => item.sku_title);
						const pkMsrpVariants = matchList.reduce((acc, item) => {
							acc[item.sku_title] = item.pk_msrp_variants;
							return acc;
						}, {});

						return {
							id: addon.addonID,
							name: addon.productName,
							description: addon.description || '',
							matchListName: matchedSkuTitle,
							matchListLabel: matchedPkMsrp,
							matchedUnitPrice: matchedUnitPrice,
							quantity: addon.quantity || 0,
							unit_price: addon.unitPrice * 100 || 0,
							amount: addon.unitPrice * 100 * addon.quantity || 0,
							skuTitles: skuTitles,
							pkMsrpVariants: pkMsrpVariants,
							effectiveStartDate,
							effectiveEndDate,
						};
					})
				);
			}
		} catch (error) {
			console.error('Error fetching details:', error);
		}
	};

	const handleInputChange = e => {
		const { name, value } = e.target;
		if (name === 'salesPersonName' && value === '') {
			setFormData({ ...formData, [name]: user?.userName });
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	const handleDateChange = (date, name) => {
		setFormData({ ...formData, [name]: date });
	};

	useEffect(() => {
		const fetchSalespersons = async () => {
			try {
				const response = await axios.get(`${serverAddress}/salespersons/account-managers`, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-User-Name': `${user?.userName}`,
					},
				});

				if (response.status === 200) {
					setSalespersons(response.data.salespersons);
				} else {
					console.log('failed to fetch salespersons. Status:', response.status);
				}
			} catch (error) {
				console.error('err fetching salespersons:', error);
			}
		};

		fetchSalespersons();
	}, [serverAddress, accessToken, user?.userName]);

	// Fetch subscription data when chargebeeId changes or on mount
	useEffect(() => {
		if (chargebeeId) {
			fetchSubscriptionData(chargebeeId);
		}
	}, [chargebeeId]);

	//renewal is one year out of validn through
	useEffect(() => {
		if (formData.validThrough && !activeSOrderId) {
			const oneYearLater = new Date(formData.validThrough);
			oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
			setFormData(prevData => ({
				...prevData,
				renewalDate: oneYearLater,
			}));
		}
	}, [formData.validThrough, activeSOrderId]);

	const fetchSubscriptionData = async id => {
		try {
			const response = await axios.get(`${serverAddress}/chargebee/subscriptions/${id}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});

			setProgressPercent(85);
			const subscription = response.data.subscription;
			const currencyCode = subscription.currency_code;

			//get titles and pkmsrp variants
			const matchList = await fetchMatchList(currencyCode);
			const companyName = subscription.cf_company_name || '';

			let salesForceAccountId = null;
			let salesForceOpportunityId = null;

			//get addons and prepopulate with pkmsrp
			const updatedAddons = await Promise.all(
				subscription.addons.map(async addon => {
					const addonResponse = await axios.get(`${serverAddress}/chargebee/addons/${addon.id}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`,
							'X-User-Name': `${user?.userName}`,
						},
					});
					const addonData = addonResponse.data.addon;

					const defaultEffectiveStartDate = formData.renewalDate
						? new Date(formData.renewalDate)
						: new Date();
					const defaultEffectiveEndDate = new Date(defaultEffectiveStartDate);
					defaultEffectiveEndDate.setFullYear(defaultEffectiveStartDate.getFullYear() + 1);

					//auto match pkmsrp variants
					const pkMsrpFromChargebee = addonData.cf_PK_MSRP;
					let matchedSkuTitle = '';
					let matchedPkMsrp = '';

					//NEW SCHEMA
					for (const item of matchList) {
						if (item.pk_msrp_variants && Array.isArray(item.pk_msrp_variants)) {
							const matchedVariant = item.pk_msrp_variants.find(
								variant => variant.pk_msrp === pkMsrpFromChargebee
							);
							if (matchedVariant) {
								matchedSkuTitle = item.sku_title;
								matchedPkMsrp = matchedVariant.pk_msrp;
								break;
							}
						}
					}

					salesForceAccountId = subscription?.cf_SF_Account_ID || null;
					salesForceOpportunityId = subscription?.cf_SF_Opportunity_ID || null;

					return {
						id: uuidv4(),
						currency: subscription.currency_code,
						name: addonData.name,
						quantity: addon.quantity || 0,
						unit_price: addon.unit_price || 0,
						originalPrice: addon.unit_price || 0, // Original price when first pulled from Chargebee for comparison + insights
						amount: addon.amount || 0,
						matchListName: matchedSkuTitle, //pre-populated SkuTitle
						matchListLabel: matchedPkMsrp, //pre-populated PK_MSRP
						skuTitles: matchList.map(item => item.sku_title), // TODO: Improve this data structure by only including the matched SKU Title
						description: '',
						pkMsrpVariants: matchList.reduce((acc, item) => {
							acc[item.sku_title] = item.pk_msrp_variants; // TODO: Improve this data structure by only including the pk_msrp variants for the matched SKU Title
							return acc;
						}, {}),
						effectiveStartDate: defaultEffectiveStartDate,
						effectiveEndDate: defaultEffectiveEndDate,
					};
				})
			);

			setAddons(updatedAddons);
			setFormData(prevData => ({
				...prevData,
				currency: currencyCode,
				companyName: companyName,
				salesForceAccountId: salesForceAccountId,
				salesForceOpportunityId: salesForceOpportunityId,
			}));
			setProgressPercent(100);
			setLoading(false);
			setLoadingComplete(true);
		} catch (error) {
			console.error('Error fetching subscription data:', error);
			setLoading(false);
			setLoadingComplete(false);
			if (error.response) {
				console.log('Response Data:', error.response.data);
				console.log('Response Status:', error.response.status);
				console.log('Response Headers:', error.response.headers);
			}
		}
	};

	//prepopulate addosn with PKMSRP
	const handleChargebeeIdChange = async e => {
		let id = e.target.value;
		setFormData(prevData => ({
			...prevData,
			chargebeeSubscriptionId: id,
		}));
		if (id.length < 16) {
			setError('Must be at least 16 characters long.');
			return;
		} else {
			setError('');
		}

		setLoading(true);
		setLoadingComplete(false);
		setProgressPercent(30);
		setAddons([]);
		setEditValues({});
		setEditingRowId(null);

		fetchSubscriptionData(id);
	};

	const fetchMatchList = async currencyCode => {
		try {
			const response = await axios.get(`${serverAddress}/products?pk_filter=${currencyCode}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
					'X-User-Name': `${user?.userName}`,
				},
			});

			const matchList = response.data; //get data from response
			setMatchList(matchList); //update state with matchList
			return matchList; //return for instant use
		} catch (error) {
			console.error('Error fetching match list:', error);
			return []; //return an empty array to prevent undefined issues
		}
	};

	const onDragEnd = result => {
		if (!result.destination) return;

		const reorderedAddons = Array.from(addons);
		const [removed] = reorderedAddons.splice(result.source.index, 1);
		reorderedAddons.splice(result.destination.index, 0, removed);

		setAddons(reorderedAddons);
	};

	const handlePlatinumSupportChange = value => {
		setFormData({ ...formData, platinumSupport: value });
	};

	const handleEditClick = id => {
		setEditingRowId(id);
		const addonToEdit = addons.find(addon => addon.id === id);
		setEditValues({
			...addonToEdit,
			unit_price: addonToEdit.unit_price / 100,
			description: addonToEdit.description || '',
		});
	};

	const handleSaveClick = async () => {
		const updatedAddons = addons.map(addon =>
			addon.id === editingRowId
				? {
						...addon,
						name: editValues.name || addon.name, //keep name updated
						matchListName: addon.isCustomRow
							? editValues.name || addon.name
							: editValues.matchListName || addon.matchListName, // Ensure SkuTitle(PC) matches Name for custom rows
						matchListLabel: editValues.matchListLabel || addon.matchListLabel,
						// matchListLabel: editValues.matchListLabel !== undefined
						// 	? editValues.matchListLabel
						// 	: addon.matchListLabel,
						quantity: editValues.quantity || addon.quantity,
						unit_price: Math.round(editValues.unit_price * 100),
						amount: (editValues.quantity || addon.quantity) * Math.round(editValues.unit_price * 100),
						description: editValues.description || addon.description,
						effectiveStartDate: editValues.effectiveStartDate || addon.effectiveStartDate,
						effectiveEndDate: editValues.effectiveEndDate || addon.effectiveEndDate,
					}
				: addon
		);

		setAddons(updatedAddons);
		setEditingRowId(null);
	};

	//TEST
	const handleRowClick = id => {
		if (editingRowId !== id) {
			handleEditClick(id);
		}
	};

	const handleAddRow = async () => {
		try {
			setLoading(true);
			// setLoadingMessage("Adding Custom Product Row...");
			setLoadingComplete(false);

			const currentDate = new Date();
			const oneYearLater = new Date(currentDate);
			oneYearLater.setFullYear(currentDate.getFullYear() + 1);

			const newAddon = {
				id: uuidv4(),
				name: '',
				description: '',
				matchListName: '',
				matchListLabel: '',
				quantity: 0,
				unit_price: 0,
				amount: 0,
				isCustomRow: true,
				skuTitles: matchList.map(item => item.sku_title),

				//NEW SCHEMA
				pkMsrpVariants: matchList.reduce((acc, item) => {
					acc[item.sku_title] = item.pk_msrp_variants.map(variant => ({
						pk_msrp: variant.pk_msrp,
						unit_price: variant.unit_price,
					}));
					return acc;
				}, {}),
				effectiveStartDate: currentDate,
				effectiveEndDate: oneYearLater,
			};
			setAddons(prevAddons => [...prevAddons, newAddon]);
			setLoadingComplete(true);
			setTimeout(() => {
				setLoading(false);
				setLoadingComplete(false);
			}, 1500);
		} catch (error) {
			console.error('Error adding new row:', error);
			setLoading(false);
			setLoadingComplete(false);
		}
	};

	const handleDeleteRow = id => {
		setAddons(prevAddons => prevAddons.filter(addon => addon.id !== id));
	};

	const handleEditChange = e => {
		const { name, value } = e.target;
		setEditValues({ ...editValues, [name]: value });
	};

	const validateMatches = () => {
		for (const addon of addons) {
			const name = addon.name;
			if (!name.includes('Platinum') && (!addon.matchListName || !addon.matchListLabel)) {
				alert('Please Match Products to Proceed');
				return false;
			}
		}
		return true;
	};

	const handleNameChange = (e, id) => {
		const selectedName = e.target.value;

		const updatedAddons = addons.map(addon =>
			addon.id === id
				? {
						...addon,
						name: selectedName,
						matchListName: addon.isCustomRow ? selectedName : addon.matchListName,
						matchListLabel: '',
					}
				: addon
		);

		if (editingRowId === id) {
			setEditValues(prevValues => ({
				...prevValues,
				name: selectedName,
				matchListName: selectedName,
				matchListLabel: '', //reset PKMSRP in editValues as well
			}));
		}

		setAddons(updatedAddons);
	};

	const handleSkuTitleChange = (selectedOption, id) => {
		const selectedSkuTitle = selectedOption.target.value;

		const updatedAddons = addons.map(addon =>
			addon.id === id
				? {
						...addon,
						matchListName: selectedSkuTitle,
						matchListLabel: '', //reset PKMSRP when SkuTitle changes
					}
				: addon
		);

		if (editingRowId === id) {
			setEditValues(prevValues => ({
				...prevValues,
				matchListName: selectedSkuTitle,
				matchListLabel: '', //reset PKMSRP in editValues as well
			}));
		}

		setAddons(updatedAddons);
	};

	const handlePkMsrpChange = (e, id) => {
		const selectedPkMsrp = e.target.value;

		const updatedAddons = addons.map(addon => {
			if (addon.id === id) {
				//both custom and cb product rows have pkmsrp selection
				const updatedAddon = {
					...addon,
					matchListLabel: selectedPkMsrp,
				};

				if (addon.isCustomRow) {
					//NEW SCHEMA
					const selectedVariant = addon.pkMsrpVariants[addon.matchListName]?.find(
						variant => variant.pk_msrp === selectedPkMsrp
					);

					const newUnitPrice = selectedVariant?.unit_price || 0;
					console.log('Selected Variant:', selectedVariant); //debug
					console.log('New Unit Price:', newUnitPrice); //debug

					//update editValues if the current row is being edited
					if (editingRowId === id) {
						setEditValues(prevValues => ({
							...prevValues,
							unit_price: newUnitPrice,
							amount: (prevValues.quantity || addon.quantity) * newUnitPrice,
							matchListLabel: selectedPkMsrp,
						}));
					}

					//update only if it's a custom row
					updatedAddon.unit_price = newUnitPrice;
					updatedAddon.amount = addon.quantity * newUnitPrice;
				} else {
					//FIXED: noncustom rows pkmsrps not updating
					if (editingRowId === id) {
						setEditValues(prevValues => ({
							...prevValues,
							matchListLabel: selectedPkMsrp, //update PK_MSRP in editValues for non-custom rows
						}));
					}
				}

				return updatedAddon;
			}
			return addon;
		});

		setAddons(updatedAddons);
	};

	const queryClient = useQueryClient();

	const generatePDFPreview = async () => {
		if (!validateMatches()) return;

		const salesOrderType = 'RENEWAL';

		// Invalidate the cache of existing sales orders,
		// because we're creating / updating one
		queryClient.invalidateQueries({
			queryKey: ['listSalespersonsSalesOrders'],
		});

		let isUploaded = false;
		if (formData.salesOrderId) {
			isUploaded = await uploadSalesOrderToCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				salesOrderType,
				totalContractValue,
				formData.salesOrderId
			);
		} else {
			isUploaded = await uploadSalesOrderToCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				salesOrderType,
				totalContractValue
			);
		}

		if (isUploaded === true) {
			const blob = await pdf(
				<SalesOrderPDFBasic
					formData={formData}
					addons={addons}
					totalContractValue={totalContractValue}
					platinumSupport={formData.platinumSupport}
				/>
			).toBlob();
			const url = URL.createObjectURL(blob);
			const pdfUrlWithParams = `${url}#toolbar=0`;
			window.open(pdfUrlWithParams, '_blank');
		}
	};

	//calculate tcv by termMonths (includes custom months from single line item)
	useEffect(() => {
		const calculateTotalPrice = () => {
			return (
				addons.reduce((total, addon) => {
					const amount = addon.amount || addon.unit_price * addon.quantity || 0;
					return total + amount;
				}, 0) / 100
			);
		};

		const calculateTermMonths = (startDate, endDate) => {
			const start = new Date(startDate);
			const end = new Date(endDate);
			const diffInMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
			return diffInMonths > 0 ? diffInMonths : 1;
		};

		const calculateTotalContractValue = () => {
			return addons.reduce((totalTCV, addon) => {
				const productTermMonths = calculateTermMonths(addon.effectiveStartDate, addon.effectiveEndDate);
				const termUsed = productTermMonths || parseInt(formData.termMonths, 10) || 0;
				const amount = addon.amount || addon.unit_price * addon.quantity || 0;
				return totalTCV + (amount / 100) * termUsed;
			}, 0);
		};

		setTotalPrice(calculateTotalPrice());
		setTotalContractValue(calculateTotalContractValue());
	}, [addons, formData.termMonths]);

	const generateSalesOrder = async () => {
		if (!validateMatches()) return;

		const salesOrderType = 'RENEWAL';

		// Invalidate the cache of existing sales orders,
		// because we're creating / updating one
		queryClient.invalidateQueries({
			queryKey: ['listSalespersonsSalesOrders'],
		});

		let isUploaded = false;
		if (formData.salesOrderId) {
			isUploaded = await uploadSalesOrderToCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				salesOrderType,
				formData.salesOrderId
			);
			console.log('Updated existing sales order in Cosmos DB');
		} else {
			isUploaded = await uploadSalesOrderToCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				salesOrderType,
				totalContractValue
			);
			console.log('Created new sales order in Cosmos DB');
		}

		if (isUploaded === true) {
			const pdfBlob = await pdf(
				<SalesOrderPDFBasic
					formData={formData}
					addons={addons}
					totalContractValue={totalContractValue}
					platinumSupport={formData.platinumSupport}
				/>
			).toBlob();
			const pdfUrl = URL.createObjectURL(pdfBlob);

			const uniqueId = uuidv4();
			const localFileName = `${formData.companyName}_${uniqueId}.pdf`;

			const downloadLink = document.createElement('a');
			downloadLink.href = pdfUrl;
			downloadLink.download = localFileName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(pdfUrl);

			await uploadSalesOrderToCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				salesOrderType,
				totalContractValue
			);
			console.log('Created new sales order in Cosmos DB');
		}
	};

	const platinumSupportOptions = [
		{ value: 'NONE', label: 'NONE' },
		{ value: 'Basic', label: 'Basic' },
		{ value: 'Standard', label: 'Standard' },
		{ value: 'Premium', label: 'Premium' },
		{ value: 'Elite', label: 'Elite' },
	];

	// Array of PK_MSRP products that should be inputted and commission-calculated
	// at monthly prices, despite being paid upfront for an entire year
	const showBillingAsMonthly = [
		'CFQ7TTC0MM8R|0002|P1Y|annual|US|USD', // Copilot for M365
	];

	const handleSave = async () => {
		const salesOrderType = 'RENEWAL';

		// Invalidate the cache of existing sales orders,
		// because we're creating / updating one
		queryClient.invalidateQueries({
			queryKey: ['listSalespersonsSalesOrders'],
		});

		if (formData.salesOrderId) {
			const isUploaded = await uploadSalesOrderToCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				salesOrderType,
				totalContractValue,
				formData.salesOrderId
			);
		} else {
			const isUploaded = await uploadSalesOrderToCosmos(
				addons,
				formData,
				user?.userName,
				serverAddress,
				accessToken,
				salesOrderType,
				totalContractValue
			);
		}
	};

	return (
		<div>
			<div className="sub-menu">
				<h2
					onClick={() => setView('client')}
					className={`sub-menu-item ${view === 'client' ? 'sub-menu-item-selected' : ''}`}
				>
					Info Section
				</h2>
				<h2
					onClick={() => setView('product')}
					className={`sub-menu-item ${view === 'product' ? 'sub-menu-item-selected' : ''}`}
				>
					Product Table
				</h2>
			</div>
			{view === 'client' && (
				<div className="flex flex-col divide-y divide-gray-200 dark:divide-slate-700">
					<div className="flex flex-col py-4 md:flex-row md:space-x-6 md:space-y-0">
						<div className="w-1/3">
							<h3 className="text-lg font-bold text-gray-700 dark:text-gray-300">Subscription Details</h3>
							<p className="m-0 text-sm text-slate-600 dark:text-slate-400">
								Enter a Chargebee subscription ID to find a customer&apos;s subsciption addon details.
								The company name will be auto populated based on the Chargebee subscription ID.
							</p>
						</div>
						<div className="rounded-xl bg-white p-4 shadow-md md:w-2/3 dark:bg-slate-700">
							<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
								<div className="md:col-span-1">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Chargebee Subscription ID *
									</label>
									<div className="relative flex items-center">
										<input
											className={`w-full rounded-xl border p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300 ${
												error ? 'border-red-500' : ''
											}`}
											type="text"
											name="chargebeeSubscriptionId"
											value={formData.chargebeeSubscriptionId}
											onChange={handleChargebeeIdChange}
											required
										/>
										<div className="absolute right-2 top-1/2 transform -translate-y-1/2">
											{loading ? (
												<Progress type="circle" percent={progressPercent} size={25} />
											) : (
												!loading &&
												loadingComplete &&
												formData.chargebeeSubscriptionId && (
													<Progress type="circle" percent={100} status="success" size={25} />
												)
											)}
										</div>
									</div>
									{error && loading && (
										<p className="mt-1 text-xs text-red-600 dark:text-red-500">
											Must be at least 16 characters long.
										</p>
									)}
									{!error && !loading && formData.chargebeeSubscriptionId && (
										<p className="mt-1 text-xs text-green-600 dark:text-green-500">
											Subscription fetched successfully.
										</p>
									)}
								</div>
								<div className="">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Status *
									</label>
									<select
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										value={formData.status || 'Draft'}
										onChange={e =>
											setFormData({
												...formData,
												status: e.target.value,
											})
										}
									>
										<option value="Draft">Draft</option>
										<option value="Pending">Pending</option>
										<option value="Approved">Approved</option>
										<option value="Rejected">Rejected</option>
									</select>
								</div>
								<div className="">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Company Name *
									</label>
									<input
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										type="text"
										name="companyName"
										value={formData.companyName}
										onChange={e => handleInputChange(e)}
										required
									/>
								</div>
								<div className="">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Sales Person Name *
									</label>
									<select
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										name="salesPersonName"
										value={formData?.salesPersonName ? formData?.salesPersonName : user?.userName}
										onChange={e => handleInputChange(e)}
										required
									>
										<option value="" disabled>
											Select Sales Person
										</option>
										{salespersons.map((person, index) => {
											// Handle case where people have different display names
											// than what is stored in Entra ID
											const salesPersonName =
												person.full_name == 'Deshawn Garner'
													? 'DeShawn Garner'
													: person.full_name;

											return (
												// Still use the person's entra name as the value
												<option key={index} value={person.full_name}>
													{salesPersonName}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-col py-4 md:flex-row md:space-x-6 md:space-y-0">
						<div className="w-1/3">
							<h3 className="text-lg font-bold text-gray-700 dark:text-gray-300">
								Term & Billing Details
							</h3>
							<p className="m-0 text-sm text-slate-600 dark:text-slate-400">
								Set the subscription’s validity period, renewal date, and term length. Adjust the
								billing frequency and net terms based on the customer’s preferences.
							</p>
						</div>
						<div className="rounded-xl bg-white p-4 shadow-md md:w-2/3 dark:bg-slate-700">
							<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
								<div className="">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Valid Through *
									</label>
									<DatePicker
										selected={formData.validThrough}
										onChange={date => handleDateChange(date, 'validThrough')}
										className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									/>
								</div>
								<div className="">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Renewal Date *
									</label>
									<DatePicker
										selected={formData.renewalDate}
										onChange={date => handleDateChange(date, 'renewalDate')}
										className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
									/>
								</div>
								<div className="">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Term (Months) *
									</label>
									<input
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										type="number"
										name="termMonths"
										value={formData.termMonths}
										onChange={e => handleInputChange(e)}
										onKeyDown={e => {
											if (e.key === '-' || e.key === 'e') {
												e.preventDefault();
											}
										}}
										min="0"
										required
									/>
								</div>
								<div className="">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Net Terms *
									</label>
									<select
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										value={formData.netTerms}
										onChange={e =>
											setFormData({
												...formData,
												netTerms: e.target.value,
											})
										}
									>
										``
										{netTermsOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
								<div className="col-span-2">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Billing Frequency *
									</label>
									<select
										className="w-full rounded-xl border border-gray-300 bg-white p-2 text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										value={formData.billingFrequency}
										onChange={e =>
											setFormData({
												...formData,
												billingFrequency: e.target.value,
											})
										}
									>
										{billingFrequencyOptions.map((option, index) => (
											<option key={index} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-col py-4 md:flex-row md:space-x-6 md:space-y-0">
						<div className="w-1/3">
							<h3 className="text-lg font-bold text-gray-700 dark:text-gray-300">Platinum Support</h3>
							<p className="m-0 text-sm text-slate-600 dark:text-slate-400">
								Select the level of platinum support provided for this client, if applicable.
							</p>
						</div>
						<div className="rounded-xl bg-white p-4 shadow-md md:w-2/3 dark:bg-slate-700">
							<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
								<div className="col-span-2">
									<label className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
										Platinum Support *
									</label>
									<select
										className="block w-full rounded-xl border border-gray-300 bg-white p-2.5 text-gray-700 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
										onChange={e => handlePlatinumSupportChange(e.target.value)}
										value={formData.platinumSupport}
									>
										{platinumSupportOptions.map((option, index) => (
											<option className="dark:bg-neutral-800" key={index} value={option.value}>
												{option.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{view === 'product' && (
				<div className="relative flex flex-col items-center w-full">
					{addons.length === 0 ? (
						<div className="flex flex-row justify-center items-center py-20">
							<ImShocked className="text-gray-400 dark:text-gray-500 text-4xl mr-4" />
							<p className="text-gray-600 dark:text-gray-400 text-lg">
								Insert Chargebee Subscription ID to see products...
							</p>
						</div>
					) : (
						<>
							<div className="w-full">
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="addons">
										{provided => (
											<div className="relative flex flex-col items-center w-full">
												<div className="flex justify-end mb-4 w-full">
													<div className="flex items-center space-x-4 bg-gray-200 dark:bg-gray-700 rounded-lg p-2 shadow-md">
														<button
															className="control-button text-white transform transition-transform duration-300 ease-in-out"
															onClick={handleAddRow}
														>
															<ImPlus className="text-xl rotate-icon text-black dark:text-white" />
															<div className="tooltip2">Add Row</div>
														</button>
														<button
															className="control-button text-white transform transition-transform duration-300 ease-in-out"
															onClick={handleSave}
														>
															<ImCloudCheck className="text-xl rotate-icon text-black dark:text-white" />
															<div className="tooltip2">Save</div>
														</button>
														<button
															className="control-button text-white transform transition-transform duration-300 ease-in-out"
															onClick={generatePDFPreview}
														>
															<ImSearch className="text-xl rotate-icon text-black dark:text-white" />
															<div className="tooltip2">Preview</div>
														</button>
														<button
															className="control-button text-white transform transition-transform duration-300 ease-in-out"
															onClick={generateSalesOrder}
														>
															<ImDownload className="text-xl rotate-icon text-black dark:text-white" />
															<div className="tooltip2">Download</div>
														</button>
													</div>
												</div>

												<table
													ref={provided.innerRef}
													{...provided.droppableProps}
													id="draggable-table"
													className="min-w-full table-outline text-sm text-black dark:bg-gray-700 dark:text-white"
												>
													<thead className="bg-gray-200 dark:bg-gray-700">
														<tr>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '20%',
																}}
															>
																Name
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '20%',
																}}
															>
																SkuTitle(PC)
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '5%',
																}}
															>
																PK_MSRP
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '5%',
																}}
															>
																Quantity
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '5%',
																}}
															>
																Sale Price
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '10%',
																}}
															>
																Amount
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '10%',
																}}
															>
																Start Date
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '10%',
																}}
															>
																End Date
															</th>
															<th
																className="header-product p-2 text-black dark:bg-gray-700 dark:text-white"
																style={{
																	width: '1%',
																}}
															>
																Actions
															</th>
														</tr>
													</thead>
													<tbody className="bg-white dark:bg-gray-700">
														{addons.map((addon, index) => {
															// const variants =
															// 	addon
															// 		.pkMsrpVariants?.[
															// 	addon
															// 		?.matchListName
															// 	];
															// const matchedUnitPrice =
															// 	variants?.[
															// 		addon
															// 			?.matchListLabel
															// 	]
															// 		?.unit_price ||
															// 	0;

															//NEW SCHEMA
															const variants =
																addon.pkMsrpVariants?.[addon?.matchListName];
															const matchedVariant = variants?.find(
																variant => variant.pk_msrp === addon?.matchListLabel
															);

															const matchedUnitPrice = showBillingAsMonthly.includes(
																matchedVariant?.pk_msrp
															)
																? matchedVariant?.unit_price / 12
																: matchedVariant?.unit_price;

															return (
																<Draggable
																	key={addon.id}
																	draggableId={addon.id}
																	index={index}
																>
																	{provided => (
																		<tr
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			// TEST
																			onClick={() => handleRowClick(addon.id)}
																			className={
																				addon.unit_price / 100 <
																				matchedUnitPrice
																					? 'bg-red-500/25'
																					: 'cursor-move text-black hover:bg-gray-100 dark:hover:bg-neutral-700 dark:text-white'
																			}
																		>
																			<td className="border px-4 py-2">
																				<div className="flex items-center">
																					<span className="mr-2 text-center">
																						::
																					</span>
																					<div className="flex flex-col">
																						{editingRowId === addon.id ? (
																							<div className="text-black dark:text-black">
																								{addon.isCustomRow ? (
																									<Select
																										value={
																											addon.name
																												? {
																														label: addon.name,
																														value: addon.name,
																													}
																												: null
																										}
																										onChange={selectedOption =>
																											handleNameChange(
																												{
																													target: {
																														value: selectedOption
																															? selectedOption.value
																															: '',
																													},
																												},
																												addon.id
																											)
																										}
																										options={addon.skuTitles
																											.slice()
																											.sort(
																												(
																													a,
																													b
																												) =>
																													a.localeCompare(
																														b
																													)
																											)
																											.map(
																												skuTitle => ({
																													label: skuTitle,
																													value: skuTitle,
																												})
																											)}
																										placeholder="Select a name"
																										styles={{
																											container:
																												provided => ({
																													...provided,
																													width: '100%',
																												}),
																										}}
																										isSearchable
																										menuPlacement="auto"
																										menuPosition="fixed"
																									/>
																								) : (
																									<span className="dark:text-white text-black">
																										{addon.name}
																									</span>
																								)}
																								<textarea
																									name="description"
																									value={
																										editValues.description
																									}
																									onChange={
																										handleEditChange
																									}
																									className="w-full mt-2 p-1 border rounded"
																									placeholder="Add description here..."
																									rows={1}
																									style={{
																										width: '80%',
																									}}
																								/>
																							</div>
																						) : (
																							<div>
																								<span>
																									{addon.name}{' '}
																								</span>
																								{addon.description && (
																									<div className="text-sm text-gray-500 mt-1">
																										{
																											addon.description
																										}
																									</div>
																								)}
																							</div>
																						)}
																					</div>
																				</div>
																			</td>

																			<td className="border px-4 py-2">
																				{addon.isCustomRow ? (
																					<span>{addon.matchListName}</span>
																				) : editingRowId === addon.id ? (
																					<div className="text-black dark:text-black">
																						<Select
																							value={
																								addon.matchListName
																									? {
																											label: addon.matchListName,
																											value: addon.matchListName,
																										}
																									: null
																							}
																							onChange={selectedOption =>
																								handleSkuTitleChange(
																									{
																										target: {
																											value: selectedOption
																												? selectedOption.value
																												: '',
																										},
																									},
																									addon.id
																								)
																							}
																							options={addon.skuTitles
																								.slice()
																								.sort((a, b) =>
																									a.localeCompare(b)
																								)
																								.map(skuTitle => ({
																									label: skuTitle,
																									value: skuTitle,
																								}))}
																							placeholder="Select a match"
																							styles={{
																								container:
																									provided => ({
																										...provided,
																										width: '100%',
																									}),
																							}}
																							isSearchable
																							menuPlacement="auto"
																							menuPosition="fixed"
																						/>
																					</div>
																				) : (
																					//for Chargebee rows not being edited, show the text
																					<span>{addon.matchListName}</span>
																				)}
																			</td>
																			<td className="border px-4 py-2">
																				<button className="control-button-hidden hover:cursor-pointer">
																					{editingRowId === addon.id ? (
																						<select
																							value={
																								addon.matchListLabel ||
																								''
																							}
																							onChange={e =>
																								handlePkMsrpChange(
																									e,
																									addon.id
																								)
																							}
																							className="form-select text-black dark:text-black"
																							disabled={
																								!addon.matchListName
																							}
																						>
																							<option value="">
																								Select PK ID
																							</option>

																							{/* NEW SCHEMA */}
																							{addon.matchListName &&
																								addon.pkMsrpVariants[
																									addon.matchListName
																								]?.map(
																									(
																										variant,
																										index
																									) => (
																										<option
																											key={index}
																											value={
																												variant.pk_msrp
																											}
																										>
																											{variant.pk_msrp
																												.split(
																													'|'
																												)
																												.slice(
																													-4
																												)
																												.join(
																													'|'
																												)}{' '}
																											{/*display last 4 sections */}
																										</option>
																									)
																								)}
																						</select>
																					) : (
																						<span>
																							{addon.matchListLabel
																								? addon.matchListLabel
																										.split('|')
																										.slice(-4)
																										.join('|')
																								: ''}
																						</span>
																					)}

																					<div className="tooltip3">
																						Cost:{' '}
																						{formatMoney(matchedUnitPrice)}
																					</div>
																				</button>
																			</td>
																			<td className="border px-4 py-2 text-center">
																				{editingRowId === addon.id ? (
																					<input
																						type="number"
																						name="quantity"
																						value={
																							editValues?.quantity ??
																							addon.quantity ??
																							0
																						}
																						onChange={handleEditChange}
																						onKeyDown={e => {
																							if (
																								e.key === '-' ||
																								e.key === 'e'
																							) {
																								e.preventDefault();
																							}
																						}}
																						min="0"
																						className="w-full text-center text-black dark:text-black"
																					/>
																				) : (
																					<span>{addon.quantity}</span>
																				)}
																			</td>
																			<td className="border px-4 py-2 text-center">
																				{editingRowId === addon.id ? (
																					<input
																						type="number"
																						name="unit_price"
																						value={
																							editValues?.unit_price ??
																							addon.unit_price ??
																							0
																						}
																						onChange={handleEditChange}
																						onKeyDown={e => {
																							if (
																								e.key === '-' ||
																								e.key === 'e'
																							) {
																								e.preventDefault();
																							}
																						}}
																						min="0"
																						step="0.01"
																						className="w-full text-center text-black dark:text-black"
																					/>
																				) : (
																					<span>
																						{formatMoney(
																							addon.unit_price / 100
																						)}
																					</span>
																				)}
																			</td>
																			<td className="border px-4 py-2 text-center">
																				<span>
																					{(addon.amount / 100)
																						.toFixed(2)
																						.replace(
																							/\d(?=(\d{3})+\.)/g,
																							'$&,'
																						)}
																				</span>
																			</td>
																			<td
																				className="border px-1 py-2"
																				style={{ width: '5%' }}
																			>
																				<DatePicker
																					selected={addon.effectiveStartDate}
																					onChange={date => {
																						const updatedAddons = [
																							...addons,
																						];
																						updatedAddons[
																							index
																						].effectiveStartDate = date;

																						// Automatically update the Effective End Date to one year after the new Effective Start Date
																						const newEffectiveEndDate =
																							new Date(date);
																						newEffectiveEndDate.setFullYear(
																							newEffectiveEndDate.getFullYear() +
																								1
																						);
																						updatedAddons[
																							index
																						].effectiveEndDate =
																							newEffectiveEndDate;

																						setAddons(updatedAddons);

																						// Also update the editValues
																						setEditValues(
																							prevEditValues => ({
																								...prevEditValues,
																								effectiveStartDate:
																									date,
																								effectiveEndDate:
																									newEffectiveEndDate,
																							})
																						);
																					}}
																					dateFormat="yyyy-MM-dd"
																					className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
																				/>
																			</td>

																			<td
																				className="border px-1 py-2"
																				style={{ width: '5%' }}
																			>
																				<DatePicker
																					selected={addon.effectiveEndDate}
																					onChange={date => {
																						const updatedAddons = [
																							...addons,
																						];
																						updatedAddons[
																							index
																						].effectiveEndDate = date;
																						setAddons(updatedAddons);

																						// Also update the editValues
																						setEditValues(
																							prevEditValues => ({
																								...prevEditValues,
																								effectiveEndDate: date,
																							})
																						);
																					}}
																					dateFormat="yyyy-MM-dd"
																					className="w-full p-2 rounded-lg border text-gray-700 dark:border-gray-600 dark:bg-slate-700 dark:text-gray-300"
																				/>
																			</td>

																			<td className="border px-4 py-2 text-center">
																				{editingRowId === addon.id ? (
																					<button
																						onClick={() =>
																							handleSaveClick()
																						}
																					>
																						Save
																					</button>
																				) : (
																					<>
																						<button
																							onClick={() =>
																								handleDeleteRow(
																									addon.id
																								)
																							}
																							className="ml-4"
																						>
																							<ImBin className="text-xl" />
																						</button>
																					</>
																				)}
																			</td>
																		</tr>
																	)}
																</Draggable>
															);
														})}
														{provided.placeholder}
													</tbody>
												</table>
												<div className="flex justify-end mt-4 space-x-8">
													<div className="text-lg font-bold text-black dark:text-white">
														Total Price:{' '}
														{(
															currencyOptions.find(
																option => option.value === formData.currency
															) || {}
														).symbol || '$'}
														{totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
													</div>
													<div className="text-lg font-bold text-black dark:text-white">
														Total Contract Value:{' '}
														{(
															currencyOptions.find(
																option => option.value === formData.currency
															) || {}
														).symbol || '$'}
														{totalContractValue
															.toFixed(2)
															.replace(/\d(?=(\d{3})+\.)/g, '$&,')}
													</div>
												</div>
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default SOrder;
