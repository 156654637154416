import { environment, graphConfig, loginRequest, serverAddress } from '../authConfig';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import LogoLoading from '../components/common/LogoLoading';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const permissionMatrixAppRoles = {
	admin: [
		'Home',
		'Commissions',
		'Consumption Report',
		'Sales Order Renewal',
		'Sales Order Acquisition',
		'My Documents',
		'Platinum Calculator',
		'Analytics',
		'Deal Desk',
		'Settings',
	],
	operations: [
		'Home',
		'Commissions',
		'Consumption Report',
		'Sales Order Renewal',
		'Sales Order Acquisition',
		'My Documents',
		'Platinum Calculator',
		'Analytics',
		'Deal Desk',
		'Settings',
	],
	finmanager: ['Home', 'Commissions', 'My Documents', 'Platinum Calculator', 'Deal Desk', 'Analytics', 'Settings'],
	finance: ['Home', 'Commissions', 'My Documents', 'Platinum Calculator', 'Deal Desk', 'Analytics', 'Settings'],
	leadership: [
		'Home',
		'Commissions',
		'My Documents',
		'Platinum Calculator',
		// 'Analytics',
		// 'Deal Desk'
	],
	ammanager: [
		'Home',
		'Commissions',
		'Consumption Report',
		'Sales Order Renewal',
		'My Documents',
		'Platinum Calculator',
		// 'Deal Desk',
	],
	aemanager: ['Home', 'Consumption Report', 'Sales Order Acquisition', 'My Documents', 'Platinum Calculator'],
	manager: [
		'Home',
		'Commissions',
		'Consumption Report',
		'Sales Order Renewal',
		'Sales Order Acquisition',
		'My Documents',
		'Platinum Calculator',
		'Deal Desk',
	],
	am: [
		'Home',
		'Sales Order Renewal',
		'Commissions',
		'My Documents',
		'Platinum Calculator',
		// 'Deal Desk'
	],
	ae: ['Home', 'Consumption Report', 'Sales Order Acquisition', 'My Documents', 'Platinum Calculator'],
	platcalc: ['Home', 'Platinum Calculator'],
	bdr: [],
	unknown: [],
};

export const operationsDynamic = 'd247f529-6b4f-4c44-8373-b37d13d78a0e';
export const accountManagersManagers = 'ea5fa75d-c057-4767-89c8-0796c1ff3a5c';
export const accountManagersDynamic = '99f4de29-54f6-45eb-9a37-f6db6c81df25';
export const accountManagersDynamicUK = 'b7b07872-5583-4b2a-8df7-f118b02da7d8';
export const accountExecutiveDynamicUS = 'cb434c27-bce0-444e-bf24-1fe89b4f4a83';
export const accountExecutiveDynamicUK = 'e95785c2-3b8b-443b-860b-70d8871bb7a1';
export const accountExecutiveDynamicALL = 'ebe29cd4-2d6b-47af-a340-0297da0701a8';
export const platinumSupport = '83ab0d75-473f-4ae0-9a6b-747351b201ff';

async function callMsGraph(accessToken) {
	console.info('Fetching user data from Entra');
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;
	headers.append('Authorization', bearer);

	const response = await fetch(graphConfig.graphGroupsEndpoint, {
		method: 'GET',
		headers: headers,
	});
	const data = await response.json();
	return data.value;
}

async function getMsGraphProfilePhoto(accessToken) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append('Authorization', bearer);
	headers.append('Content-Type', 'image/jpeg');

	try {
		const response = await fetch(`${graphConfig.graphMeEndpoint}/photo/$value`, {
			method: 'GET',
			headers: headers,
		});

		if (!response.ok) {
			throw new Error(`Error fetching profile photo: ${response.statusText}`);
		}

		const arrayBuffer = await response.arrayBuffer();

		const base64String = btoa(
			new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
		);

		return `data:image/jpeg;base64,${base64String}`;
	} catch (error) {
		console.error('Error fetching profile photo:', error);
	}
}

async function getCurrentUserRegion(accessToken) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;
	headers.append('Authorization', bearer);

	try {
		const response = await fetch(`${graphConfig.graphMeEndpoint}`, {
			method: 'GET',
			headers: headers,
		});

		if (!response.ok) {
			throw new Error(`Error fetching user region: ${response.statusText}`);
		}

		const data = await response.json();
		// console.info(data);
		return data.officeLocation;
	} catch (error) {
		console.error('Error fetching user region:', error);
		return null;
	}
}

const AuthContext = createContext(undefined);

export default function AuthProvider({ children }) {
	const [loading, setLoading] = useState(true);
	const [loginResponse, setLoginResponse] = useState();
	const [accessToken, setAccessToken] = useState();
	const [user, setUser] = useState();
	const { instance, accounts } = useMsal();

	useEffect(() => {
		// Usually I wouldn't care for such a small thing in a cleanup function, but this is in useContext
		// so is app wide and should be handled gracefully
		let isMounted = true;
		setLoading(true);

		const initializeAuth = async () => {
			if (instance && accounts.length > 0) {
				try {
					await instance.initialize();
					console.info('INSTANCE IS INITIALIZED!');
					const [idToken, userRoles] = await getUserData();
					if (!idToken || !userRoles) {
						return;
					}
					await getAccessToken(idToken);
				} catch (error) {
					console.error('Error initializing MSAL instance:', error);
				}
			} else {
				console.info('INSTANCE IS NOT INITIALIZED');
			}
		};

		initializeAuth();
		setLoading(false);

		return () => {
			isMounted = false;
		};
	}, [instance, accounts]);

	async function getUserData() {
		console.info('Getting user data');
		const silentTokenResponse = await instance.acquireTokenSilent({
			...loginRequest,
			account: accounts[0],
		});
		// const graphResponse = await callMsGraph(
		// 	silentTokenResponse.accessToken
		// );
		const profilePhoto = await getMsGraphProfilePhoto(silentTokenResponse.accessToken);
		const roles = accounts[0].idTokenClaims.roles;

		const officeLocation = await getCurrentUserRegion(silentTokenResponse.accessToken);

		let isElevatedPermissions = false;
		//TODO: This is a temporary solution to check for elevated permissions. This will be replaced by the Entra App Roles
		let userRoles = roles ? roles : 'default';
		if (roles.includes('manager') || roles.includes('operations')) {
			isElevatedPermissions = true;
		}

		console.debug('User roles are: ', accounts[0]['roles']);

		// TODO: Caching of user data to enable silent SSO. Need to implement pull from cache if exists

		const cachedDataRoles = JSON.stringify({
			officeLocation: officeLocation,
			userRoles: roles ? roles : '',
			userName: accounts[0].name ? accounts[0].name : '',
			userEmail: accounts[0].username ? accounts[0].username : '',
		});
		localStorage.setItem('appRoles', cachedDataRoles);

		const idToken = silentTokenResponse.idToken;

		setUser({
			userName: accounts[0].name ? accounts[0].name : undefined,
			userRoles: roles ? roles : '',
			officeLocation: officeLocation,
			//entraGroup: entraGroup,
			isElevatedPermissions: isElevatedPermissions,
			profilePhoto: profilePhoto,
		});

		Sentry.setUser({
			fullName: accounts[0].name,
			email: accounts[0].username,
		});

		return [idToken, userRoles];
	}

	async function handleLogin() {
		const loginResult = await instance.loginPopup(loginRequest);
		if (environment !== 'production') {
			console.info('Login response:', loginResult);
		}
		setLoginResponse(loginResult);
	}

	async function handleLogout() {
		Object.keys(localStorage).forEach(key => {
			if (key !== 'dark-mode') {
				localStorage.removeItem(key);
			}
		});

		const logoutRequest = {
			account: instance.getActiveAccount(),
			mainWindowRedirectUri: '/',
			postLogoutRedirectUri: '/',
		};

		const logoutResult = await instance.logout(logoutRequest);
		if (environment !== 'production') {
			console.info('Logout response:', logoutResult);
		}
	}

	async function getAccessToken(idToken) {
		const access_token = localStorage.getItem('saleshubAccessToken.v0.3.0'); //On any changes to the underlying token configuration, update the token name
		if (access_token) {
			console.info('Retrieved access token from local storage');
			setAccessToken(access_token);
		} else {
			const response = await axios.get(`${serverAddress}/token`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`,
					'X-User-Name': `${accounts[0].name}`,
				},
			});
			console.info('Fetched access token');
			localStorage.setItem('saleshubAccessToken.v0.3.0', response.data.access_token);
			setAccessToken(response.data.access_token);
		}
	}

	return (
		<AuthContext.Provider
			value={{
				accessToken,
				user,
				loading,
				getAccessToken,
				handleLogin,
				handleLogout,
			}}
		>
			{loading ? (
				<div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-slate-800 bg-custom-green-gradient">
					<LogoLoading />
				</div>
			) : (
				children
			)}
		</AuthContext.Provider>
	);
}

export function useAuth() {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error('useAuth must be used inside of an AuthProvider');
	}

	return context;
}

export function Authorize({ allowedRoles }) {
	const { user, loading } = useAuth();
	const location = useLocation();

	return user?.userRoles ? (
		<>
			{allowedRoles.find(role => user?.userRoles.includes(role)) ? (
				<Outlet />
			) : (
				<Navigate to="/unauthorized" state={{ from: location }} replace />
			)}
		</>
	) : (
		<>
			<LogoLoading />
		</>
	);
}
