import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useDarkMode } from '../context/DarkModeContext';
import { serverAddress } from '../authConfig';
import { formatMoney, getMarkersForMonth } from '../utils';
import FilterNav from '../components/commissions/FilterNav';
import useDebounce from '../hooks/useDebounce';
import LogoLoading from '../components/common/LogoLoading';
import CommissionsList from '../components/commissions/CommissionsList';
import CommissionDetails from '../components/commissions/CommissionDetails';

function Commissions() {
	const { darkMode } = useDarkMode();
	const { user, accessToken } = useAuth();
	const [selectedSalesperson, setSelectedSalesperson] = useState(user?.userName);
	const [selectedMonth, setSelectedMonth] = useState('All Time');
	const [searchInput, setSearchInput] = useState('');
	const [selectedStatus, setSelectedStatus] = useState('All Statuses');
	const [activeSalesOrderId, setActiveSalesOrderId] = useState('');
	const [salesOrders, setSalesOrders] = useState([]);
	const [continuationToken, setContinuationToken] = useState(null);

	const debouncedSearchInput = useDebounce(searchInput, 500);

	const fetchSalespersons = async () => {
		if (!user?.isElevatedPermissions) return [{ full_name: user?.userName }];

		const response = await axios.get(`${serverAddress}/salespersons/account-managers`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				'X-User-Name': user?.userName,
			},
		});
		return response.data.salespersons;
	};

	const { data: salespersons } = useQuery(['salespersons', user?.userName], fetchSalespersons, {
		staleTime: 30 * 60 * 1000,
	});

	const fetchSalesOrders = async (selectedSalesperson, continuationToken) => {
		if (!selectedSalesperson) return;

		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			'X-User-Name': selectedSalesperson,
		};
		if (continuationToken) {
			headers['cont-token'] = continuationToken;
		}

		const response = await axios.get(`${serverAddress}/sales-orders/renewal?salesperson=${selectedSalesperson}`, {
			headers,
		});

		setContinuationToken(response.data.continuation_token);
		if (response.data.results) {
			setSalesOrders(prev => [...prev, ...response.data.results]);
		}
	};

	useEffect(() => {
		setContinuationToken(null);
		setSalesOrders([]);
	}, [selectedSalesperson]);

	const { isPending, isError, isLoading, error } = useQuery({
		queryKey: ['listSalespersonsSalesOrders', continuationToken, selectedSalesperson],
		refetchOnWindowFocus: false,
		queryFn: () => {
			fetchSalesOrders(selectedSalesperson, continuationToken);
		},
		staleTime: 2 * 60 * 1000, // Cache data for 2 mins. User can clear cache by refreshing
		placeholderData: previousData => previousData,
	});

	const currentDate = new Date();
	const prevMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
	const prevMonthOrdinal = prevMonthDate.getMonth() + 1;
	const currentMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
	const currentMonthOrdinal = currentMonthDate.getMonth() + 1;

	const prevMonthStats = getMarkersForMonth(prevMonthOrdinal, salesOrders);
	const currentMonthStats = getMarkersForMonth(currentMonthOrdinal, salesOrders);

	return (
		<div className={`h-screen ${darkMode ? 'bg-slate-800' : 'bg-gray-100'}`}>
			<div className="h-full max-w-[1920px] mx-auto space-y-4">
				<div className={`${darkMode ? 'bg-slate-700' : 'bg-white'} rounded-lg shadow-lg p-4`}>
					<FilterNav
						isElevatedPermissions={user?.isElevatedPermissions}
						salespersons={salespersons}
						selectedSalesperson={selectedSalesperson}
						selectedMonth={selectedMonth}
						searchInput={searchInput}
						selectedStatus={selectedStatus}
						handleSelectedSalesperson={setSelectedSalesperson}
						handleSelectedMonth={setSelectedMonth}
						handleSearchInput={setSearchInput}
						handleSelectedStatus={setSelectedStatus}
					/>

					<div className="overflow-x-auto scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-transparent mt-4">
						<table className={`min-w-full divide-y ${darkMode ? 'divide-slate-600' : 'divide-gray-200'}`}>
							<thead>
								<tr>
									<th
										className={`px-4 py-2 text-left text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Month
									</th>
									<th
										className={`px-4 py-2 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										TCVs
									</th>
									<th
										className={`px-4 py-2 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Commission
									</th>
									<th
										className={`px-4 py-2 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Profit/Month
									</th>
									<th
										className={`px-4 py-2 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Profit/Year
									</th>
									<th
										className={`px-4 py-2 text-right text-xs font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}
									>
										Profit/TCVs
									</th>
								</tr>
							</thead>
							<tbody className={`divide-y ${darkMode ? 'divide-slate-600' : 'divide-gray-200'}`}>
								<tr>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'}`}
									>
										Last Month
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(prevMonthStats.totalContractValues)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(prevMonthStats.commission)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(prevMonthStats.monthlyProfit)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(prevMonthStats.monthlyProfit * 12)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(prevMonthStats.profitTcvs)}
									</td>
								</tr>
								<tr>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'}`}
									>
										This Month
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(currentMonthStats.totalContractValues)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(currentMonthStats.commission)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(currentMonthStats.monthlyProfit)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(currentMonthStats.monthlyProfit * 12)}
									</td>
									<td
										className={`px-4 py-2 whitespace-nowrap text-sm ${darkMode ? 'text-white' : 'text-gray-900'} text-right`}
									>
										${formatMoney(currentMonthStats.profitTcvs)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="flex flex-col lg:flex-row gap-4 h-[calc(100vh-280px)]">
					{isLoading ? (
						<div className="w-full flex justify-center">
							<LogoLoading />
						</div>
					) : error ? (
						<div className="w-full text-center text-red-400">Error loading sales orders</div>
					) : (
						<>
							<CommissionsList
								activeSalesOrderId={activeSalesOrderId}
								salesOrders={salesOrders}
								debouncedSearchInput={debouncedSearchInput}
								selectedMonth={selectedMonth}
								selectedStatus={selectedStatus}
								selectedSalesperson={selectedSalesperson}
								handleActiveSalesOrderChange={setActiveSalesOrderId}
							/>
							<CommissionDetails
								activeSalesOrderId={activeSalesOrderId}
								serverAddress={serverAddress}
								accessToken={accessToken}
								userName={user?.userName}
								selectedSalesperson={selectedSalesperson}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Commissions;
